<template>
  <div class="pbf">
     
     <BrandTabGallery
      :inurl="'/photos'"
      :intags="tags"
      :product-id="props.id"
      />

  </div>
</template>


<script setup lang="ts">
 
import { TagsBrandProductGallery } from '@/types/other'

interface Props {
  id: number
}

const props = defineProps<Props>()

const tags = ref(structuredClone(TagsBrandProductGallery))

</script>


<style scoped>
 

</style>
